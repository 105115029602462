body {
  overflow-x: hidden;
  max-width: 100vw;
  margin: 0;
  font-family: 'Jost', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loading-container {
  width: 100vw;
  height: 100vh;
}

#root {
  width: 100vw;
  min-height: 100vh;
}

::-webkit-scrollbar {
  width: 8px;
  height: 120px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #75b4f2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #75b4f2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}
